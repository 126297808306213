<jet-page-ribbon></jet-page-ribbon>
<div>
    <router-outlet name="navbar"></router-outlet>
</div>
<div class="container-fluid">
    <div class="card jh-card">
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
    <jet-footer></jet-footer>
</div>
