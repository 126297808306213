<div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h2 jhiTranslate="password.title" [translateValues]="{username: account.login}" *ngIf="account">Password for [<strong>{{account.login}}</strong>]</h2>

            <div class="alert alert-success" *ngIf="success" jhiTranslate="password.messages.success">
                <strong>Password changed!</strong>
            </div>
            <div class="alert alert-danger" *ngIf="error"  jhiTranslate="password.messages.error">
                <strong>An error has occurred!</strong> The password could not be changed.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" jhiTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>

            <form name="form" role="form" (ngSubmit)="changePassword()" [formGroup]="passwordForm">

                <div class="form-group">
                    <label class="form-control-label" for="currentPassword" jhiTranslate="global.form.currentpassword.label">Current password</label>
                    <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                           placeholder="{{'global.form.currentpassword.placeholder' | translate}}"
                           formControlName="currentPassword">
                    <div *ngIf="passwordForm.get('currentPassword').invalid && (passwordForm.get('currentPassword').dirty || passwordForm.get('currentPassword').touched)" >
                        <small class="form-text text-danger"
                               *ngIf="passwordForm.get('currentPassword').errors.required" jhiTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="newPassword" jhiTranslate="global.form.newpassword.label">New password</label>
                    <input type="password" class="form-control" id="newPassword" name="newPassword"
                           placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                           formControlName="newPassword">
                    <div *ngIf="passwordForm.get('newPassword').invalid && (passwordForm.get('newPassword').dirty || passwordForm.get('newPassword').touched)">
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.required" jhiTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.minlength" jhiTranslate="global.messages.validate.newpassword.minlength">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.maxlength" jhiTranslate="global.messages.validate.newpassword.maxlength">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <jet-password-strength-bar [passwordToCheck]="passwordForm.get('newPassword').value"></jet-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" jhiTranslate="global.form.confirmpassword.label">New password confirmation</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                           placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                           formControlName="confirmPassword">
                    <div *ngIf="passwordForm.get('confirmPassword').invalid && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)">
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.required" jhiTranslate="global.messages.validate.confirmpassword.required">
                            Your confirmation password is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.minlength" jhiTranslate="global.messages.validate.confirmpassword.minlength">
                            Your confirmation password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.maxlength" jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your confirmation password cannot be longer than 50 characters.
                        </small>
                    </div>
                </div>
                <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary" jhiTranslate="password.form.button">Save</button>
            </form>
        </div>
    </div>
</div>
