<nav class="navbar navbar-light navbar-expand-md bg-light">
    <a class="navbar-brand logo" routerLink="/" (click)="collapseNavbar()">
        <span class="logo-img"></span>
        <span jhiTranslate="global.title" class="navbar-title">Users</span> <span class="navbar-version">{{version}}</span>
    </a>
    <a class="navbar-toggler d-lg-none" href="javascript:void(0);" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <fa-icon icon="bars"></fa-icon>
    </a>
    <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link" routerLink="/" (click)="collapseNavbar()">
                    <span>
                        <fa-icon icon="home"></fa-icon>
                        <span jhiTranslate="global.menu.home">Home</span>
                    </span>
                </a>
            </li>
            <!-- jhipster-needle-add-element-to-menu - JHipster will add new menu items here
            <li *ngSwitchCase="true" ngbDropdown class="nav-item dropdown pointer" display="dynamic" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu">
                    <span>
                        <fa-icon icon="th-list"></fa-icon>
                        <span jhiTranslate="global.menu.entities.main">
                            Entities
                        </span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
                </ul>
            </li>
            -->
            <li *jetHasAnyAuthority="['ROLE_ADMIN', 'VIEW_MENU_ROLE', 'VIEW_MENU_USER']" ngbDropdown class="nav-item dropdown pointer" display="dynamic" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu">
                    <span>
                        <fa-icon icon="user-plus"></fa-icon>
                        <span jhiTranslate="global.menu.admin.main">Administration</span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
                    <li *jetHasAnyAuthority="'VIEW_MENU_ROLE'">
                        <a class="dropdown-item" routerLink="admin/privilege" routerLinkActive="active" (click)="collapseNavbar()">
                          <fa-icon icon="user" fixedWidth="true"></fa-icon>
                          <span jhiTranslate="global.menu.admin.privilege">Privilege</span>
                        </a>
                    </li>
                    <li *jetHasAnyAuthority="'VIEW_MENU_USER'">
                        <a class="dropdown-item" routerLink="admin/user-management" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="user" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.userManagement">User management</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="admin/metrics" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="tachometer-alt" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.metrics">Metrics</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="admin/health" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="heart" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.health">Health</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="admin/configuration" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="list" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.configuration">Configuration</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="admin/audits" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="bell" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.audits">Audits</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="admin/logs" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="tasks" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.logs">Logs</span>
                        </a>
                    </li>
                    <li *ngIf="swaggerEnabled">
                        <a class="dropdown-item" routerLink="admin/docs" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="book" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.admin.apidocs">API</span>
                        </a>
                    </li>
                    <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
                </ul>
            </li>
            <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" *ngIf="languages && languages.length > 1">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                    <span>
                        <fa-icon icon="flag"></fa-icon>
                        <span jhiTranslate="global.menu.language">Language</span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                    <li *ngFor="let language of languages">
                        <a class="dropdown-item" [jetActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">{{language | findLanguageFromKey}}</a>
                    </li>
                </ul>
            </li>
            <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                  <span *ngIf="!getImageUrl()">
                    <fa-icon icon="user"></fa-icon>
                    <span jhiTranslate="global.menu.account.main">
                      Account
                    </span>
                  </span>
                  <span *ngIf="getImageUrl()">
                      <img [src]="getImageUrl()" class="profile-image rounded-circle" alt="Avatar">
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" routerLink="settings" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="wrench" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.account.settings">Settings</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" routerLink="password" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="lock" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.account.password">Password</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" (click)="logout()" id="logout">
                            <fa-icon icon="sign-out-alt" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.account.logout">Sign out</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="false">
                        <a class="dropdown-item" (click)="login()" id="login">
                            <fa-icon icon="sign-in-alt" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.account.login">Sign in</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="false">
                        <a class="dropdown-item" routerLink="register" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="user-plus" fixedWidth="true"></fa-icon>
                            <span jhiTranslate="global.menu.account.register">Register</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
