import { NgModule } from '@angular/core';

import { UsersSharedLibsModule, FindLanguageFromKeyPipe, JetAlertComponent, JetAlertErrorComponent } from './';

@NgModule({
  imports: [UsersSharedLibsModule],
  declarations: [FindLanguageFromKeyPipe, JetAlertComponent, JetAlertErrorComponent],
  exports: [UsersSharedLibsModule, FindLanguageFromKeyPipe, JetAlertComponent, JetAlertErrorComponent]
})
export class UsersSharedCommonModule {}
