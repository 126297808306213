import { Privilege } from './privilege.model';
export class Authority {
    constructor(
        public id?: number,
        public name?: string,
        public tenantId?: number,
        public privileges?: Privilege[]
    ) {
    }
}
