<div class="row">
    <div class="col-md-3">
        <span class="hipster img-fluid rounded"></span>
    </div>
    <div class="col-md-9">
        <h1 class="display-4" jhiTranslate="home.title">Welcome, Java Hipster!</h1>
        <p class="lead" jhiTranslate="home.subtitle">This is your homepage</p>

        <div [ngSwitch]="isAuthenticated()">
            <div class="alert alert-success" *ngSwitchCase="true">
                <span id="home-logged-message" *ngIf="account" jhiTranslate="home.logged.message"
                    [translateValues]="{username: account.login}"> You are logged in as user "{{account.login}}". </span>
            </div>
        </div>
    </div>
</div>
