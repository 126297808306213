import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { UsersSharedCommonModule, JetLoginModalComponent, HasAnyAuthorityDirective } from './';

@NgModule({
  imports: [UsersSharedCommonModule],
  declarations: [JetLoginModalComponent, HasAnyAuthorityDirective],
  entryComponents: [JetLoginModalComponent],
  exports: [UsersSharedCommonModule, JetLoginModalComponent, HasAnyAuthorityDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersSharedModule {
  static forRoot(): ModuleWithProviders<UsersSharedModule> {
    return {
        ngModule: UsersSharedModule
    };
}
}
