<div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h1 jhiTranslate="reset.request.title">Reset your password</h1>

            <div class="alert alert-danger" jhiTranslate="reset.request.messages.notfound" *ngIf="errorEmailNotExists">
                <strong>Email address isn't registered!</strong> Please check and try again.
            </div>

            <div class="alert alert-warning" *ngIf="!success">
                <p jhiTranslate="reset.request.messages.info">Enter the email address you used to register.</p>
            </div>

            <div class="alert alert-success" *ngIf="success === 'OK'">
                <p jhiTranslate="reset.request.messages.success">Check your emails for details on how to reset your password.</p>
            </div>

            <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()" [formGroup]="resetRequestForm">
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.email.label">Email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="{{'global.form.email.placeholder' | translate}}"
                           formControlName="email">
                    <div *ngIf="resetRequestForm.get('email').invalid && (resetRequestForm.get('email').dirty || resetRequestForm.get('email').touched)">
                        <small class="form-text text-danger"
                           *ngIf="resetRequestForm.get('email').errors.required" jhiTranslate="global.messages.validate.email.required">
                            Your email is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="resetRequestForm.get('email').errors.email" jhiTranslate="global.messages.validate.email.invalid">
                            Your email is invalid.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="resetRequestForm.get('email').errors.minlength" jhiTranslate="global.messages.validate.email.minlength">
                            Your email is required to be at least 5 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="resetRequestForm.get('email').errors.maxlength" jhiTranslate="global.messages.validate.email.maxlength">
                            Your email cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>
                <button type="submit" [disabled]="resetRequestForm.invalid" class="btn btn-primary" jhiTranslate="reset.request.form.button">Reset</button>
            </form>
        </div>
    </div>
</div>
