import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DEBUG_INFO_ENABLED } from './app.constants';
import {errorRoute, navbarRoute} from 'app/layouts';
import { UserRouteAccessService } from 'app/core';

const LAYOUT_ROUTES = [navbarRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'admin',
          loadChildren: () => import('./admin/admin-routing.module').then((m) => m.AdminRoutingModule)
        },
        ...LAYOUT_ROUTES
      ],
      // { enableTracing: DEBUG_INFO_ENABLED }
    )
  ],
  exports: [RouterModule]
})
export class UsersAppRoutingModule {}
